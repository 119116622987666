/* App.css */
.app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 20px; */
    width: 100%; /* Ensure it fits the full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .list-item-container {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    width: 100%; /* Fit the full width of the parent container */
    max-width: 800px; /* Optional: Set a maximum width for larger screens */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box; /* Ensure padding and border are included in the width */
    background-color: rgb(245 245 245/var(--tw-bg-opacity));
  }
  .exhibition-fetch{
    width: 100%;
  }
  .list-item-image {
    width: 60px; /* Adjusted size */
    height: 60px;
    border-radius: 4px;
    margin-right: 15px;
    object-fit: cover;
  }
  
  .list-item-details {
    flex: 1;
    overflow: hidden; /* Ensure text overflow is handled within this container */
  }
  
  .list-item-title,
  .list-item-author,
  .list-item-description,
  .list-item-price {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .list-item-title {
    font-size: 1em; /* Adjusted font size */
    margin: 0;
    font-weight: bold; /* Bold font */
  }
  
  .list-item-author {
    font-size: 0.85em; /* Adjusted font size */
    color: #555;
    margin: 1px 0;
  }
  
  .list-item-description {
    font-size: 0.85em; /* Adjusted font size */
    color: #777;
  }
  
  .list-item-price-container {
    display: flex;
    align-items: center;
    flex-shrink: 0; /* Prevent shrinking */
  }
  
  .list-item-price {
    font-size: 0.9em; /* Adjusted font size */
    margin: 0 10px 0 0; /* Add space between price and button */
  }
  
  .list-item-join-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 5px;
    font-size: 0.9em; /* Adjusted font size */
  }
  
  .list-item-join-button:hover {
    background-color: #0056b3;
  }
  


  /* exhibition single */

  .exhibition-container {
    font-family: Arial, sans-serif;
    color: #333;
    width: 100%;
   
    margin: 0 auto;
    padding: 0px;
  }
  
  .back-button {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .back-button svg {
    margin-right: 10px;
  }
  
  .banner-container {
    position: relative;
    margin-bottom: 10px;
    
  }
  
  .banner-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    height: 150px;
    
  }
  
  .thumbnail-container {
    position: absolute;
    bottom: -30px;
    left: 30px;
  }
  
  .thumbnail-image {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    border-color: white;
    border-width: 5px;
    border-style: solid;
  }
  
  .exhibition-content {
    padding: 20px;
  }
  
  h1 {
    font-size: 2em;
    margin-top: 20px;
  }
  
  .owned-by {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 1em;
    line-height: 1.5;
  }
  

  
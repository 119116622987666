@tailwind base;
@tailwind components;
@tailwind utilities;

#carousel.swiper {
  @apply w-full sm:rounded-2xl;
}

#carousel .swiper-slide {
  @apply max-w-[90%] sm:max-w-none;
}

:is(#carousel, .collections-slide):hover :is(.swiper-button-prev, .swiper-button-next) {
  @apply opacity-100;
}

:is(#carousel, .collections-slide) :is(.swiper-button-prev, .swiper-button-next) {
  @apply hidden h-12 w-12 overflow-hidden rounded-full bg-white/80 p-4 opacity-0 transition-opacity duration-300 group-hover:opacity-100 sm:flex;
}

:is(#carousel, .collections-slide) :is(.swiper-button-prev, .swiper-button-next)::after {
  @apply text-xl font-black text-slate-800;
}

.collections-slide :is(.swiper-button-prev, .swiper-button-next) {
  @apply border !bg-white shadow-lg;
}

.collections-slide .swiper-button-disabled {
  @apply !hidden;
}

#main-tabs {
  @apply [mask:linear-gradient(to_right,rgba(0,0,0,0)_0%,rgb(0,0,0)_5%,rgb(0,0,0)_95%,rgba(0,0,0,0)_100%)] sm:[mask:none];
}

#main-tabs .swiper {
  @apply px-4 sm:px-0;
}

#main-tabs .swiper-slide {
  @apply w-fit;
}

#main-tabs :is(.swiper-button-next, .swiper-button-prev) {
  display: none;
}

.collections-slide {
  @apply py-4;
}

.collections-slide .swiper-slide {
  @apply w-3/4;
}
